import React from "react";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import WrapperDashEst from "../../authWrapper/WrapperDashEst";

import Chatroom from "../Chatroom";

function ChatEsta() {
  return (
    <>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Chat</span>{" "}
            </div>
          </>
        }
      />
      <Chatroom />
    </>
  );
}

export default ChatEsta;
