import { useEffect, useState } from "react";
import SetAvailibility from "./SetAvailibility";
import DashHeader from "../../dashboard/DashHeader";
import { Badge, Calendar } from "antd";
import dayjs from "dayjs";
import {
  fetchDateScheduleBart,
  fetchMonthlyScheduleBart,
  fetchSetAvailability,
} from "../../../axios/axios";
import { useSelector } from "react-redux";

import styles from "../celender.module.css";
import EditIcon from "@mui/icons-material/Edit";
import TimerIcon from "@mui/icons-material/Timer";
import { motion, AnimatePresence } from "framer-motion";
import CancelIcon from "@mui/icons-material/Cancel";
import { TbCalendarTime } from "react-icons/tb";
import useSwipeHandlerNoLimit from "../../useSwipeHandlerNoLimit";

function CelenderBrt() {
  const user = useSelector((state) => state.user.user);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [activeslot, setActiveSlot] = useState({ activeTime: "", day: "" });
  const [availability, setAvailability] = useState();
  const [panel, setPanel] = useState(dayjs().format("YYYY-MM-DD"));
  const [showdayschedule, setShowDaySchedule] = useState(false);
  const [activday, setActiveDay] = useState(dayjs().format("YYYY-MM-DD"));
  const [activejob, setActiveJob] = useState();
  const week = [
    {
      day: "sun",
      date: "",
    },
    {
      day: "mon",
      date: "",
    },
    {
      day: "tue",
      date: "",
    },
    {
      day: "wed",
      date: "",
    },
    {
      day: "thu",
      date: "",
    },
    {
      day: "fri",
      date: "",
    },
    {
      day: "sat",
      date: "",
    },
  ];
  const [selectedDates, setSelectedDates] = useState({});

  const getDaySchedule = ({ daydate }) => {
    fetchDateScheduleBart({
      date: dayjs(daydate).format("YYYY-MM-DD"),
      token: user?.token,
    })
      .then((result) => {
        // Pending
        setActiveDay(dayjs(daydate).format("YYYY-MM-DD"));
        setActiveJob(result.data.data);
        setShowDaySchedule(true);
      })
      .catch((err) => {
        console.log("Monthly err", err);
      });
  };

  const getSetAvailability = () => {
    fetchSetAvailability({
      token: user?.token,
    })
      .then((result) => {
        setAvailability(result.data.data);
      })
      .catch((err) => {
        console.log("Monthly err", err);
      });
  };

  const onPanelChange = (value, mode) => {
    setPanel(value.format("YYYY-MM-DD"));
  };

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = dayjs(startDate);
    var stopDate = dayjs(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(dayjs(currentDate).format("DD"));
      currentDate = dayjs(currentDate).add(1, "days");
    }
    dateArray.push(dayjs(stopDate).format("DD"));
    return dateArray;
  }

  useEffect(() => {
    let year = panel.split("-")[0];
    let month = panel.split("-")[1];

    fetchMonthlyScheduleBart({ year, month, token: user.token })
      .then((result) => {
        let dArr = [];
        result.data.data.forEach((element) => {
          let x = getDates(element.startdate, element.enddate);

          dArr = [...dArr, ...x];
        });
        let newDarr = [...new Set(dArr)];
        let ob = {};

        newDarr = newDarr.forEach((elem) => (ob[elem] = 1));

        setSelectedDates(ob);
      })
      .catch((err) => {
        console.log("Monthly err", err);
      });
  }, [panel]);

  useEffect(() => {
    getSetAvailability();
  }, [showModal3]);

  const today = dayjs();
  const generateWeek = () => {
    const GenWeek = [];
    for (let index = 0; index < 7; index++) {
      let x = today.add(index, "day");
      GenWeek.push({ day: dayjs(x).get("day"), date: dayjs(x).get("date") });
    }
    return GenWeek;
  };

  const IterateWeek = generateWeek();

  const swipeHandlers = useSwipeHandlerNoLimit({
    onPanelChange: onPanelChange,
    panel: panel,
  });

  const dateCellRender = (value) => {
    let keys = Object.keys(selectedDates);
    const listData = [];
    if (keys.indexOf(`${value.date()}`) !== -1) {
      listData.push(
        ...Array.from({ length: selectedDates[`${value.date()}`] })
      );
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
        }}>
        {listData.map((item, ind) => (
          <Badge color="red" size="default" key={`${ind}_Badge`} />
        ))}
      </div>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") {
      if (!current.isSame(panel, "month")) {
        return <div style={{ visibility: "hidden" }}></div>;
      }
      return dateCellRender(current);
    }

    return <></>;
  };

  const renderHeader = () => {
    return (
      <div className="flex items-center gap-2">
        <img
          src="/images/LessTan.svg"
          className="w-10 h-auto transition-transform cursor-pointer aspect-square hover:scale-110"
          onClick={() => onPanelChange(dayjs(panel).subtract(1, "month"))}
        />
        <p className="text-[#7D8DA6] font-poppins text-xl leading-6 font-medium">
          {dayjs(panel).format("MMMM")}
        </p>
        <p className="text-[#7D8DA6] font-poppins text-xl leading-6 font-medium">
          {dayjs(panel).format("YYYY")}
        </p>
        <img
          src="/images/GreaterThan.svg"
          className="w-10 h-auto transition-transform cursor-pointer aspect-square hover:scale-110"
          onClick={() => onPanelChange(dayjs(panel).add(1, "month"))}
        />
      </div>
    );
  };

  return (
    <>
      <DashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Calendar</span>{" "}
            </div>
          </>
        }
      />

      <div className="flex mt-5">
        <div
          className="flex flex-col w-[350px] mr-5 grow mt-2.5 max-md:mt-8 max-md:max-w-full"
          {...swipeHandlers}>
          <Calendar
            // key={JSON.stringify(selectedDates)}
            headerRender={renderHeader}
            onPanelChange={onPanelChange}
            onSelect={(dt) => {
              if (dt.isSame(panel, "month")) {
                getDaySchedule({ daydate: dt });
              }
            }}
            cellRender={cellRender}
            value={dayjs(panel)}
          />
        </div>
        <div className="relative w-[30%]">
          <AnimatePresence>
            {showdayschedule && (
              <motion.div
                key="showdayschedule"
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="absolute flex flex-col flex-1 w-full px-5 pb-20 bg-white shadow-2xl grow pt-7 rounded-3xl max-md:mt-6 max-md:max-w-full">
                <p
                  className="absolute cursor-pointer top-2 right-5"
                  onClick={() => setShowDaySchedule(false)}>
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      transform: "translate(30%,-30%)",
                      cursor: "pointer",
                      color: "#FF3333",
                      background: "white",
                      fontSize: "24px",
                      borderRadius: "50%",
                    }}
                  />
                </p>
                <div className="flex max-md:flex-wrap max-md:max-w-full">
                  <div className="flex justify-between flex-auto my-auto text-[16px] text-slate-900">
                    {activday}
                    {activejob?.length > 0 ? (
                      <p>Total Schedule: {activejob?.length}</p>
                    ) : (
                      <p>No Schedule</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-9">
                  {activejob?.map((elem) => (
                    <div key={elem._id} className="flex gap-2">
                      <div className="w-fit">
                        <p className="pt-2 text-xs whitespace-nowrap">
                          {dayjs(elem.startTime, "HH:mm").format("hh:mm A")}
                        </p>
                      </div>
                      <div className="bg-[#7575f8] text-white w-full flex flex-col gap-1 px-4 py-1 rounded-xl text-xs cursor-pointer">
                        <p>{elem?.establishmentName}</p>
                        <p>{elem?.jobTitle}</p>
                        <div className="flex items-center gap-2">
                          <TbCalendarTime size={12} />
                          Shift Date:{" "}
                          {dayjs(elem.startdate).format("DD-MM-YYYY")} -{" "}
                          {dayjs(elem.enddate).format("DD-MM-YYYY")}
                        </div>
                        <div className="flex items-center gap-2">
                          <TbCalendarTime size={12} />
                          Shift Time:{" "}
                          {dayjs(elem.startTime, "HH:mm").format(
                            "hh:mm A"
                          )} - {dayjs(elem.endTime, "HH:mm").format("hh:mm A")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            )}
            {!showdayschedule && (
              <motion.div
                key="bartenderavail"
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="flex flex-col flex-1 w-full px-5 pb-20 bg-white shadow-2xl grow pt-7 rounded-3xl max-md:mt-6 max-md:max-w-full">
                <div className="flex max-md:flex-wrap max-md:max-w-full">
                  <div className="flex-auto my-auto text-[16px] text-slate-900">
                    Your Availability
                  </div>
                </div>
                <div className="mt-9">
                  {week.map((elem, ind) => (
                    <div key={`asd${ind}`} className="flex w-full mb-2">
                      <div className="p-1 text-center text-red-500 bg-red-200 border-2 border-red-500 border-solid rounded-xl min-w-[18%] h-fit">
                        <p className="font-bold">{IterateWeek[ind].date}</p>
                        <p>{week[IterateWeek[ind].day].day.toUpperCase()}</p>
                      </div>
                      <div className="min-w-[75%]">
                        {availability?.[week[IterateWeek[ind].day].day].length <
                        1 ? (
                          <p className={styles.unavailText}>Unavailable</p>
                        ) : (
                          ""
                        )}

                        {availability?.[week[IterateWeek[ind].day].day].map(
                          (el) => (
                            <p className="flex items-center px-1 ml-3 text-base">
                              <TimerIcon
                                sx={{ marginRight: "2px" }}
                                style={{ width: "15px" }}
                              />
                              {dayjs(el.starttime, "HH:mm").format("hh:mm A")} -{" "}
                              {dayjs(el.endtime, "HH:mm").format("hh:mm A")}
                            </p>
                          )
                        )}
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setActiveSlot({
                            ...activeslot,
                            activeTime:
                              availability?.[week[IterateWeek[ind].day].day],
                            day: week[IterateWeek[ind].day].day,
                          });
                          setShowModal3(true);
                        }}>
                        <EditIcon sx={{ color: "gray" }} />
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      <>
        {showModal2 && (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-3xl mx-auto my-6">
                {/*content*/}
                <div className="border-0 rounded- shadow-lg relative rounded-[20px] flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex flex-col items-center  pb-10  text-base text-center capitalize bg-white rounded-3xl max-w-[538px] max-md:px-5">
                    <span
                      onClick={() => setShowModal2(false)}
                      className="text-[22px] cursor-pointer self-end pt-2 pr-4 mb-2">
                      &#10005;
                    </span>
                    <button
                      onClick={() => {
                        setShowModal3(true) || setShowModal2(false);
                      }}
                      className="justify-center items-center mx-10 px-12 py-3  text-black bg-amber-300 rounded-[122px] max-md:px-5">
                      Change Availability
                    </button>
                    <button className="justify-center items-start px-7 py-3 mx-10 mt-4 text-black bg-white border border-amber-300 border-solid rounded-[122px] max-md:pr-5 max-md:pl-6">
                      Set Yourself Unavailable
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
          </>
        )}
      </>

      <>
        {showModal3 && (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="">
                <div className="pb-4 bg-white rounded-2xl ">
                  <span
                    onClick={() => setShowModal3(false)}
                    className="flex flex-col items-end pt-2 pr-4 text-2xl cursor-pointer">
                    &#10005;
                  </span>
                  <SetAvailibility
                    activeslot={activeslot.activeTime}
                    day={activeslot.day}
                    setShowModal3={setShowModal3}
                  />
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
          </>
        )}
      </>
    </>
  );
}

export default CelenderBrt;
