import JobCards from "../../../jobCards/JobCards";

function CurrentGigsEstablishment({ data, dayjs }) {
  return (
    <div className="flex flex-wrap w-full gap-6 px-2 pt-6">
      {data.map((elem, index) => {
        return (
          <JobCards
            elem={elem}
            index={index}
            key={`job_${elem?._id}_${index}_Internal`}
            Jtype={1}
          />
        );
      })}
      {data?.length < 1 && (
        <div className="flex flex-col items-center justify-center w-full">
          <img src="/images/No_Data.svg" sizes="20" alt="" />
          <p className="text-xl font-medium font-Roboto translate-y-[-1rem]">
            No Current Gigs{" "}
          </p>
        </div>
      )}
    </div>
  );
}
export default CurrentGigsEstablishment;
