import React, { useEffect, useState } from "react";
import style from "../Earning.module.css";
import DashHeader from "../../dashboard/DashHeader";
import { getBartPayment, sendPaymentRemind } from "../../../axios/axios";
import { useSelector } from "react-redux";
import config from "../../../config/configuration";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { formatNumber } from "../../../utils/helper";
import { Modal } from "antd";
import { CircularProgress } from "@mui/material";
import { useSwipeable } from "react-swipeable";

export default function EarningsBart() {
  const [filter, setfilter] = useState(true);
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [myTimeOut, setMytimeOut] = useState();

  const [paydata, setPayData] = useState({
    prev: false,
    next: false,
    search: "",
    pending: "",
    paid: "",
    filter: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchPaymentDetails = async () => {
    try {
      const data = await getBartPayment({ filter, token: user?.token, search });
      setPayData(data.data);
      setLoading(false)
    } catch (error) {
      console.error("Error:", error.message);

    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setfilter(false),
    onSwipedRight: () =>
      setfilter(true)
  });

  const sendPaymentReminder = ({ eid }) => {
    sendPaymentRemind({ token: user?.token, eid })
      .then((res) => toast.success(res.message))
      .catch((err) => console.log(err));
  };

  const handleViewInvoice = (item) => {
    window.location.href = item;
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, [filter]);

  useEffect(() => {
    clearTimeout(myTimeOut);

    setMytimeOut(
      setTimeout(() => {
        fetchPaymentDetails();
      }, [700])
    );
  }, [search]);

  return (
    <>
      {/* <Modal open={loading} footer={null}>
        <div>
          <h1>Earnings</h1>
        </div>  
      </Modal> */}
      <DashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Earnings</span>{" "}
              {/* <span className="text-yellow-400 ">{user?.name}</span> */}
            </div>
          </>
        }
      />
      <div className="flex flex-col px-5 py-10 mt-8 text-sm leading-6 bg-white shadow-2xl rounded-3xl text-neutral-700 max-md:px-5 max-md:max-w-full" {...swipeHandlers}>
        {/* header */}
        <div className="flex gap-5 self-center px-1 py-1  text-[15px] leading-5 bg-white border border-gray-200 border-solid rounded-[100px] max-md:ml-2.5">
          <div
            className={`flex-auto my-auto px-5 py-3.5 rounded-[100px] cursor-pointer ${filter ? "text-white bg-red-500 font-medium" : ""
              }`}
            onClick={() => setfilter(true)}>
            Received Payment
          </div>
          {/* text-neutral-700 */}
          <div
            className={`flex-auto my-auto px-5 py-3.5 rounded-[100px] cursor-pointer ${!filter ? "text-white bg-red-500 font-medium" : ""
              }`}
            onClick={() => setfilter(false)}>
            Pending Payment
          </div>
        </div>
        <div className="flex justify-between text-center">
          <div className="z-10 flex self-start">
            <div className="relative">
              <img
                loading="lazy"
                src="/images/coins.png"
                className="shrink-0 max-w-full aspect-square mt-6 w-[90px]"
              />
              <span className="absolute right-0 text-lg top-5 ">&#10022;</span>
              <span className="absolute right-0 top-[60px] text-lg">
                &#10022;
              </span>
              <span className="absolute text-lg left-2 top-8 ">&#10022;</span>
            </div>
            <div className="flex flex-col self-start ml-4 mt-9">
              <div className="text-[13px] text-neutral-400">
                {filter ? "Total Payments Received" : "Total Upcoming Payments"}
              </div>
              <div className="mt-2 text-[25px] self-start font-medium text-black">
                ${" "}
                {filter
                  ? formatNumber(paydata.paid)
                  : formatNumber(paydata.pending)}
              </div>
            </div>
          </div>
          <div className="flex gap-2 self-end px-5 py-3.5 mb-7 max-w-full text-sm bg-white border border-gray-200 border-solid shadow-sm rounded-[100px] text-neutral-400 w-[300px] max-md:px-5 max-md:mt-10">
            <input
              className="flex-auto my-auto border-none outline-none"
              type="text"
              value={search}
              placeholder="Search establishments"
              onChange={(e) => setSearch(e.target.value)}
            />
            <img
              loading="lazy"
              src="/images/search.png"
              className=" h-[16px] w-[16px]"
            />
          </div>
        </div>
        <div className="grid max-w-full grid-cols-2 gap-5 mx-auto mt-5 xl:grid-cols-3 ">
          {!loading ? paydata.history.map((item, index) => {
            return (
              <div
                style={{
                  boxShadow: "0px 0px 14.764px 0px rgba(0, 0, 0, 0.12)",
                }}
                key={`PayData_${index}`}
                className="flex flex-col w-full p-4 bg-white rounded-md grow max-md:px-5 max-md:mt-8 max-md:max-w-full">
                <div className="flex justify-between w-full">
                  <div className="flex gap-3">
                    <img
                      loading="lazy"
                      src={
                        `${config.cdnFront}/${item.establishmentDatail[0].EprofileImg}` ||
                        ""
                      }
                      className="shrink-0 aspect-square h-[59px] w-[59px]"
                    />
                    <div className="flex flex-col my-auto">
                      <div
                        className={`text-[15px]  text-black} ${style.header}`}>
                        {item.establishmentDatail[0].establishmentName}
                      </div>
                      <div className="flex gap-[2px] items-center  text-[12px]">
                        <img
                          loading="lazy"
                          src="/images/Comminity/location.svg"
                          className="shrink-0 self-stretch aspect-square mt-[2px] h-[18px] w-[18px]"
                        />
                        <div className="self-stretch my-auto text-black">
                          {item.establishmentDatail[0].location.city}
                        </div>
                        <div className="self-stretch pl-[2px] my-auto text-zinc-400">
                          {formatNumber(item.distance)} mi
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 text-[24px] pl-4 font-medium text-red-500">
                    $
                    {item.assigned
                      .filter((elem) => elem.bartender_id === user._id)
                      .map((filteredElem) =>
                        formatNumber(filteredElem.PaymentDetail.Amount)
                      )}
                  </div>
                </div>
                <div className="shrink-0 mt-1 h-0.5 bg-gray-200 border border-gray-200 border-solid" />
                <div className="mt-1.5 text-[12px] text-neutral-400">
                  {item.isSuccess ? (
                    <p
                      className="cursor-pointer"
                      onClick={() =>
                        handleViewInvoice(item?.PayDetails?.invoicePdf)
                      }>
                      View Payment Details
                    </p>
                  ) : (
                    ""
                  )}
                  {!item.isSuccess ? (
                    <div className="">
                      {`${Math.abs(
                        dayjs(item?.enddate).diff(
                          dayjs(new Date()).toDate(),
                          "day"
                        )
                      )}${" "}`}
                      days are past!{" "}
                      <span
                        className="font-semibold text-red-500 hover:cursor-pointer"
                        onClick={() =>
                          sendPaymentReminder({ eid: item.establishmentid })
                        }>
                        Send Reminder
                      </span>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          }) : <div className="flex items-center justify-center h-[11.6rem]">
            <CircularProgress />{" "}
            <p className="pl-2 text-xl font-poppins">Loading...</p>
          </div>}
        </div>
        {paydata.history?.length < 1 &&
          <div className="flex flex-col items-center justify-center w-full">
            <img
              loading="lazy"
              src="/images/no_earnings.svg"
              sizes="20"
              alt=""
            />
            <p className="text-xl font-medium font-Roboto translate-y-[-1rem]">
              No {`${filter ? "Received" : "Pending"}`} Payment {" "}
            </p>
          </div>
        }
      </div>
    </>
  );
}
