import { useState } from "react";
import WrapperDashEst from "../../../authWrapper/WrapperDashEst";
import style from "../../dashboard.module.css";
import DropdownEst from "../DropdownEst";
import NotificationEst from "../NotificationEst";
import { Link, useNavigate } from "react-router-dom";
import configuration from "../../../../config/configuration";
import {
  CalculateReaminTime,
  convertremainingtime,
  RemainingTime,
  upconvertremainingtime,
} from "../../../../utils/TimeFormatting";
import JobCards from "../../../jobCards/JobCards";

function PendingAcceptance({ data, dayjs }) {
  const FormatTime = (msg) => {
    const today = dayjs().startOf("day");
    const yesterday = today.subtract(1, "day");

    const msgDate = dayjs(msg.createdAt).startOf("day");
    let dateLabel;

    if (msgDate.isSame(today)) {
      dateLabel = "Today";
    } else if (msgDate.isSame(yesterday)) {
      dateLabel = "Yesterday";
    } else {
      dateLabel = msgDate.format("D MMMM YYYY");
    }
    return dateLabel;
  };

  return (
    <div>
      <div className="flex flex-wrap w-full gap-6 px-2 pt-6 max-md:max-w-full ">
        {data.map((elem, index) => {
          return (
            <JobCards
              elem={elem}
              index={index}
              Jtype={2}
              key={`job_${elem?._id}_${index}_pending`}
            />
          );
        })}
        {data?.length < 1 && (
          <div className="flex flex-col items-center justify-center w-full">
            <img src="/images/No_Data.svg" sizes="20" alt="" />
            <p className="text-xl font-medium font-Roboto translate-y-[-1rem]">
              No Pending Gigs{" "}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
export default PendingAcceptance;
