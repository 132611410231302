import dayjs from "dayjs";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import configuration from "../../config/configuration";
import { formatNumber, handleFloat } from "../../utils/helper";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { getTimeDifferenceFromNow } from "../../utils/TimeFormatting";

function BarJobCards({
  elem,
  index,
  Jtype,
  handleApplyGig,
  handleCancleGig,
  handleSubmitReviewonEstab,
  setShowModal3,
  setactiveEstab,
  givenrating,
  setGivenRating
}) {
  const navigate = useNavigate();

  return (
    <div
      className="relative flex w-[290px] p-1 flex-col rounded-xl cursor-pointer border border-solid bg-zinc-50 border-neutral-200"
      key={`job_${elem?._id}_${index}`}>
      <div
        loading="lazy"
        style={{
          backgroundImage: `url('${configuration.cdnFront}/${elem?.establishmentDatail?.EprofileImg}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="w-full shadow-lg aspect-[2] rounded-xl"
        onClick={() => navigate(`/gig/${elem._id}`)}
      />
      <div className="flex text-blue-500 top-32 right-4 px-3 absolute justify-center items-center bg-gray-100 rounded-[7px] border-solid border-2 min-w-[87px] max-w-fit text-[11px] h-[25px] pl-1 ">
        ${formatNumber(elem.payHourly)}
        <span className=" text-black text-[9px] pl-[1px]"> / hour</span>
      </div>

      <div className="flex gap-1 mt-2.5 font-medium">
        <div className="flex flex-col flex-1">
          <div className="tracking-tighter text-black grow text-l whitespace-nowrap">
            {elem?.establishmentDatail?.establishmentName}
          </div>
          <div className="text-xs tracking-tighter text-black grow whitespace-nowrap">
            {elem?.jobTitle}
          </div>
        </div>
        <div className="flex-auto text-[10px] h-full whitespace-nowrap text-orange-400 capitalize text-center mt-1">
          {Jtype != 3 && getTimeDifferenceFromNow(elem?.startdate)}
        </div>
      </div>
      <div className="mt-[1px] text-xs leading-5 whitespace-nowrap text-neutral-400">
        {`${elem?.establishmentDatail?.location?.city}, ${elem?.establishmentDatail?.location?.state}`}
      </div>
      <div className="flex gap-1 mt-1 text-xs text-neutral-700">
        <img
          loading="lazy"
          src="/images/completeProfile/clock.png"
          className=" w-3 h-3 mt-[2px]"
        />
        <div className="flex-auto tracking-tigter text-[11px] whitespace-nowrap">
          Shift Dates: {dayjs(elem.startdate).format("ddd MMM DD YYYY")} -{" "}
          {dayjs(elem.enddate).format("ddd MMM DD YYYY")}
        </div>
      </div>
      <div className="flex gap-1 mt-1 text-xs text-neutral-700">
        <img
          loading="lazy"
          src="/images/completeProfile/clock.png"
          className=" w-3 h-3 mt-[2px]"
        />
        <div className="flex-auto tracking-tigter text-[11px] whitespace-nowrap">
          Shift Time: {dayjs(elem.startdate).format("hh:mm A")} -{" "}
          {dayjs(elem.enddate).format("hh:mm A")} | Pay: $
          {handleFloat(elem?.payHourly)}/hr
        </div>
      </div>
      {Jtype === 1 && (
        <div className="flex gap-2 px-1 py-1 mt-3 text-xs text-center whitespace-nowrap">
          <Button
            type="text"
            style={{ height: "auto" }}
            className="grow py-2 justify-center w-[50%] text-black border border-red-300 border-solid rounded-[79.075px]"
            onClick={() => handleCancleGig(elem._id)}>
            Delete Application
          </Button>
        </div>
      )}
      {Jtype === 2 && (
        <div className="flex gap-2 px-1 py-1 mt-3 text-xs text-center whitespace-nowrap">
          <Link
            to={`/gig/${elem._id}`}
            type="text"
            style={{ height: "auto" }}
            className="flex items-center justify-center w-[50%] text-black border border-amber-300 border-solid rounded-[79.075px]">
            View Details
          </Link>
          <Button
            type="text"
            style={{ height: "auto" }}
            className="flex items-center justify-center w-[50%] z-50 text-black bg-amber-300 rounded-[79.075px]"
            onClick={() => handleApplyGig(elem._id)}>
            Apply
          </Button>
        </div>
      )}
      {Jtype === 3 && !elem?.Reviewed ? (
        <button
          onClick={() => {
            setShowModal3(true);
            setGivenRating({
              ...givenrating,
              establishmentId: elem?.establishmentid,
            });
            setactiveEstab(elem?.establishmentDatail);
          }}
          className="justify-center items-center mx-1  py-2 mt-3 text-xs text-center text-black whitespace-nowrap border border-amber-300 border-solid rounded-[79.075px]">
          Write Review
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export default BarJobCards;
