import React, { useEffect, useState } from "react";
import { getNearbyEstablishments, prevworkedData } from "../../../axios/axios";
import { useSelector } from "react-redux";
import config from "../../../config/configuration";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import { CircularProgress } from "@mui/material";
import { formatNumber } from "../../../utils/helper";

export default function NearbyEst() {
  const user = useSelector((state) => state.user.user);
  const [showModal, setshowModal] = useState(false);
  const [prevWork, setprevWork] = useState(false);
  const [prevlist, setPrevList] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    item_per_page: 12,
    total_page: 1,
    history: [],
  });
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    item_per_page: 12,
    total_page: 1,
    history: [],
  });

  const navigate = useNavigate();
  const nearbyestab = () => {
    getNearbyEstablishments({
      token: user?.token,
      page: data.page,
    })
      .then((result) => {
        let res = result.data;
        let x = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page,
          item_per_page: res.item_per_page,
          history: [...res.history],
        };

        setData(x);
      })
      .catch((err) => console.log(err));
  };

  const decreasePage = () => {
    let x = { ...data };
    x = { ...x, page: x.page - 1 };
    setData(x);
  };
  const increasePage = () => {
    let x = { ...data };
    x = { ...x, page: x.page + 1 };
    setData(x);
  };

  useEffect(() => {
    nearbyestab();
  }, []);

  useEffect(() => {
    nearbyestab();
  }, [data.page]);

  const handlePrevWorkClick = (id) => {
    prevworkedData({ token: user?.token, eid: id })
      .then((result) => {
        let res = result.data;
        let x = { ...prevlist };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page,
          item_per_page: res.item_per_page,
          history: [...res.history],
        };

        setPrevList(x);
        setprevWork(true);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <div>
        <div className="grid grid-cols-3 gap-3 mt-10 ">
          {data.history.map((item, ind) => {
            return (
              <div
                className="flex flex-col w-full px-5 py-5 bg-white border-2 rounded-md shadow-xl cursor-pointer grow "
                onClick={() => handlePrevWorkClick(item._id)}
                key={`${item._id}_Estab_${ind}`}>
                <div className="flex gap-3">
                  <img
                    loading="lazy"
                    src={`${config.cdnFront}/${item.EprofileImg}` || ""}
                    className="shrink-0 rounded-xl aspect-[0.98] h-[62px] w-[62px]"
                  />
                  <div className="flex flex-col self-start grow shrink-0 basis-0 w-fit">
                    <div className={`text-base text-black}`}>
                      {item.establishmentName}
                    </div>
                    <div className="flex gap-2.5 mt-1 text-base">
                      <div className="flex gap-1.5 text-black whitespace-nowrap">
                        <img
                          loading="lazy"
                          src="/images/Comminity/location.svg"
                          className="shrink-0 aspect-square w-[22px]"
                        />
                        <div className="my-auto text-sm">
                          {item.location.city}
                        </div>
                      </div>
                      <div className="flex-auto my-auto text-sm text-zinc-400">
                        {formatNumber(item.distance)} mi
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shrink-0 mt-1 h-0.5 bg-gray-200 border border-gray-200 border-solid" />
                <div className="flex items-center gap-3 mt-2 text-base whitespace-nowrap max-md:pr-5">
                  <div className="flex gap-1.5 items-center self-stretch my-auto text-neutral-400">
                    <div className="self-stretch my-auto text-sm">Hired</div>
                    <img
                      loading="lazy"
                      src="/images/Building.svg"
                      className="shrink-0 self-stretch aspect-square w-[18px]"
                    />
                    <div className="self-stretch my-auto text-sm font-medium text-black ">
                      {item.TotalHired}
                    </div>
                    <div className="self-stretch my-auto mr-3 text-sm">
                      bartenders
                    </div>
                  </div>
                  <div className="flex gap-1.5 items-center self-stretch my-auto">
                    <div className="shrink-0 self-stretch bg-zinc-300  h-[25px] w-[3px]" />
                    <img
                      loading="lazy"
                      src="/images/StartRating.svg"
                      className="shrink-0 self-stretch aspect-square h-[18px] mt-[3px] w-[18px]"
                    />
                    <div className="self-stretch my-auto text-sm font-medium text-black">
                      {item.totalReviews}
                    </div>
                  </div>
                </div>
                {item?.HiredDetails?.length > 0 && (
                  <div className="flex items-center w-full h-10 gap-2 mt-4 shrink-0">
                    <p className="text-[0.8rem] font-normal font-poppins w-1/2 whitespace-nowrap">
                      Previously worked
                    </p>

                    <div className="relative flex w-full h-10">
                      {item.HiredDetails.slice(0, 5).map((elem, index) => (
                        <img
                          src={`${config.cdnFront}/${elem?.profileImg}` || ""}
                          className="w-10 h-10 rounded-[50%] border-2 border-white absolute"
                          key={`${elem._id}_Hired`}
                          style={{
                            left: `${index * 20}px`,
                            zIndex: index,
                          }}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {showModal ? (
            <>
              <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6">
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    <div className="flex pl-20 items-start self-end px-px max-w-full text-2xl mb-4 leading-10 text-black w-[367px]">
                      <div className="self-end flex-auto mt-6">
                        Previously Worked
                      </div>
                      <button
                        className="relative "
                        onClick={() => setshowModal(false)}>
                        <span className="mr-4">&#10005;</span>
                      </button>
                    </div>
                    {/* <Modal1></Modal1> */}
                  </div>
                </div>
              </div>
              <div className="fixed inset-0 z-40 bg-black opacity-10"></div>
            </>
          ) : null}
        </div>
      </div>
      {data.history.length > 0 && (
        <div
          style={{
            width: "auto",
            margin: "25px 0 0 0",
            padding: "0 20px",
          }}
          className="flex items-center justify-center text-center">
          <Button disabled={!data.prev} type="text" onClick={decreasePage}>
            Previous
          </Button>

          {Array.from({ length: data.total_page }).map((elem, ind) => (
            <Button
              key={`Pagi${ind}`}
              disabled={!data.prev && data.page != 1}
              style={{
                height: "20px",
                width: "20px",
                padding: "0",
                fontSize: "12px",
                margin: "0 3px",
                background:
                  data.page === ind + 1 ? "rgb(255, 51, 51)" : "white",
                color: data.page === ind + 1 ? "white" : "black",
              }}>
              {ind + 1}
            </Button>
          ))}

          <Button
            type="text"
            onClick={() => increasePage()}
            disabled={!data.next}>
            Next
          </Button>
        </div>
      )}
      <Modal
        open={prevWork}
        centered
        sx={{
          color: "white",
        }}
        footer={null}
        onOk={() => console.log("THIS")}
        onCancel={() => setprevWork(false)}>
        <div className="flex flex-col items-center justify-center gap-2 p-4 font-Righteous ">
          <h1 className="text-2xl font-bold font-Righteous">
            Previously Worked
          </h1>
          {prevlist.history.length > 0 ? (
            prevlist.history.map((eleum) => (
              <div
                className="border-[1px] border-[#E4E4E4] rounded-xl p-2 flex w-full "
                key={`${eleum._id}`}>
                <img
                  loading="lazy"
                  src={`${config.cdnFront}/${eleum?.profileImg}` || ""}
                  className="shrink-0 rounded-[50%] h-[62px] w-[62px] border-2 border-y-neutral-300"
                />
                <div className="ml-2">
                  <div className="flex items-center justify-center gap-2 h-1/2">
                    <p className="font-[400] text-[15px] font-Righteous ">
                      {eleum?.Data?.name}
                    </p>
                    <div className="flex items-center justify-center gap-1">
                      <img
                        loading="lazy"
                        src="/images/LocationIcon.svg"
                        className="shrink-0 aspect-square w-[22px]"
                      />
                      {eleum?.location.city}
                    </div>{" "}
                    {formatNumber(eleum?.distance)} mi
                  </div>
                  <div className="flex gap-4 h-1/2">
                    <div className="flex self-stretch gap-2 my-auto text-xl font-medium text-black whitespace-nowrap">
                      <img
                        loading="lazy"
                        src="/images/Group.svg"
                        className="w-5 shrink-0 aspect-square"
                      />
                      <div className="my-auto text-base">
                        {eleum?.totalFriends}
                      </div>
                    </div>
                    <div className="flex gap-1.5 self-stretch my-auto text-xl font-medium text-black whitespace-nowrap">
                      <img
                        loading="lazy"
                        src="/images/Building.svg"
                        className="w-5 shrink-0 aspect-square"
                      />
                      <div className="my-auto text-base">
                        {eleum?.totalJobs}
                      </div>
                    </div>
                    <div className="flex self-stretch gap-2 my-auto text-xl font-medium text-black whitespace-nowrap">
                      <img
                        loading="lazy"
                        src="/images/StartRating.svg"
                        className="w-5 shrink-0 aspect-square"
                      />
                      <div className="my-auto text-base">
                        {eleum?.totalReviews}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h1>No Previously Worked Bartenders! </h1>
          )}
        </div>
      </Modal>
    </div>
  );
}
