import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAllEstab } from "../../../../axios/axios";
import "./JobStyle.css";
import { toast } from "react-toastify";
import { countWords } from "../../../../utils/countwords";
import dayjs from "dayjs";
import { Switch } from "antd";

function Jp1({ jobdata, setJobData, setActiveJTab, handleChange }) {
  const user = useSelector((state) => state.user.user);
  const [establist, setEstabList] = useState([]);
  const [loading, setLoading] = useState(true);
  // TOOGLEling the value of the pickers
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const [isdayjob, setIsDayJob] = useState(jobdata?.daytype || false);

  const [pickershow, setPickerShow] = useState({
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
  });
  const [errormsg, setErrorMsg] = useState({});

  const handleShowPicker = (picker) => {
    const refs = {
      startDate: startDateRef,
      endDate: endDateRef,
      startTime: startTimeRef,
      endTime: endTimeRef,
    };

    if (pickershow[picker]) {
      refs[picker].current.blur();
    } else {
      refs[picker].current.showPicker();
    }

    setPickerShow((prevState) => ({
      ...prevState,
      [picker]: !prevState[picker],
    }));
  };

  const checkInput = ["jobTitle", "jobDiscription", "startdate", "enddate"];

  const handleSaveNext = () => {
    if (isdayjob && jobdata.startdate != "") {
      setJobData({
        ...jobdata,
        enddate: jobdata.startdate,
      });
    }
    const errors = checkInput.reduce((acc, field) => {
      if (!jobdata[field] || jobdata[field].trim() === "") {
        acc[field] = `${field} is required`;
      }
      return acc;
    }, {});

    if (Object.keys(errors).length > 0) {
      toast.error("Mandatory Fields Empty");
      setErrorMsg(errors);
    } else if (!checkJobDuration()) {
      toast.error("Gig Duration should be atleast 1 hr");
    } else {
      setErrorMsg({});
      setActiveJTab(2);
    }
  };

  const handleSingleDayJob = () => {
    let val = !isdayjob;
    setIsDayJob(val);

    setJobData({ ...jobdata, daytype: val });
    if (isdayjob && jobdata.startdate != "") {
      setJobData({
        ...jobdata,
        enddate: jobdata.startdate,
      });
      delete errormsg["enddate"];
    }
  };

  const checkJobDuration = () => {
    if (
      (isdayjob && jobdata.starTime && jobdata.endTime) ||
      (jobdata.startdate == jobdata.enddate &&
        jobdata.starTime &&
        jobdata.endTime)
    ) {
      const start = new Date(`1970-01-01T${jobdata?.starTime}:00Z`);
      const end = new Date(`1970-01-01T${jobdata?.endTime}:00Z`);
      const durationInMs = end - start;
      const durationInHours = durationInMs / (1000 * 60 * 60);
      return durationInHours >= 1;
    } else if (jobdata.startdate < jobdata.enddate) {
      return true;
    }
  };

  const getAllEstab = () => {
    fetchAllEstab({ token: user?.token })
      .then((res) => {
        setEstabList(res.data.history);
        setLoading(false);
        if (res.data.history.length > 0) {
          setJobData({
            ...jobdata,
            establishmentid: jobdata.establishmentid || res.data.history[0]._id,
            starTime: jobdata.starTime || dayjs().add(10, "m").format("HH:mm"),
            endTime: jobdata.endTime || dayjs().add(1.17, "h").format("HH:mm"),
          });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllEstab();
  }, []);

  return (
    <div className="flex flex-col items-center bg-white shadow-lg rounded-[25px] p-4 h-full w-full overflow-auto no-scrollbar">
      <style>
        {`
          .hide-calendar::-webkit-calendar-picker-indicator {
            display: none;
          }
          .hide-calendar::-moz-calendar-picker-indicator {
            display: none;
          }
          .hide-calendar::-ms-clear {
            display: none;
          }
            
        `}
      </style>
      <div className="flex flex-col items-center">
        <p className="text-2xl font-righteous">Post A Job</p>
        <p className="text-sm font-poppins text-subtext">Add New Job</p>
      </div>
      <div className="w-[90%] flex flex-col gap-8">
        <div className="flex items-center justify-center gap-1">
          <div>
            Post as <span className={`text-red-600`}>‘Emergency Hiring’</span>
          </div>
          <label className="mt-1">
            <Switch
              checked={jobdata.urgent}
              onChange={() =>
                setJobData({ ...jobdata, urgent: !jobdata.urgent })
              }
              style={{ backgroundColor: jobdata.urgent ? "red" : "gray" }}
            />
          </label>
        </div>
        <div className="flex justify-between w-full px-6">
          <div className="flex border-2 border-[#9d9d9d] rounded-[40px] items-center w-[40%] p-2 gap-4">
            <img
              loading="lazy"
              src="/images/completeProfile/building.png"
              className="size-5"
            />
            <div className="w-[90%]">
              <div className="text-sm">
                Choose Establishment <span className="text-error">*</span>
              </div>
              <div>
                {!loading ? (
                  <select
                    className="w-full outline-none"
                    value={jobdata.establishmentid} // Bind value to jobdata.establishmentid
                    onChange={(e) =>
                      setJobData({
                        ...jobdata,
                        establishmentid: e.target.value,
                      })
                    }>
                    {establist.map((elem, index) => (
                      <option value={elem?._id} key={`Estab_${index}`}>
                        {elem?.establishmentName}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select></select>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              errormsg?.jobTitle ? `eFrrorInputField` : "validInputField"
            }>
            <img
              loading="lazy"
              src="/images/completeProfile/drink_glass.png"
              className="size-5"
            />
            <div className="w-[90%]">
              <div
                className={
                  errormsg?.jobTitle ? "text-red-500 text-sm" : "text-sm"
                }>
                Job Title <span className="text-error">*</span>
              </div>
              <div>
                <input
                  className={`${
                    errormsg?.jobTitle
                      ? "text-sm outline-none w-full placeholder:text-red-600"
                      : " text-sm outline-none w-full"
                  }
                `}
                  type="text"
                  value={jobdata.jobTitle}
                  name="jobTitle"
                  onChange={(e) => {
                    if (errormsg?.jobTitle) {
                      delete errormsg["jobTitle"];
                    }
                    handleChange({ e });
                  }}
                  placeholder="Enter job title"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            errormsg?.jobDiscription
              ? `errorTextAreaField`
              : "flex overflow-hidden p-[0.5rem] gap-[16px] border-[2px] border-solid border-[#9d9d9d] rounded-[40px] ml-[1.25rem] mr-[1.25rem]"
          }>
          <img
            loading="lazy"
            src="/images/pen.svg"
            className="p-2 text-red-500 h-fit "
          />
          <div className="w-[95%]">
            <div
              className={
                errormsg?.jobDiscription ? "text-red-500 text-sm" : "text-sm"
              }>
              Job Description <span className="text-error">*</span>
            </div>
            <textarea
              rows="4"
              placeholder="Job Description"
              className={
                errormsg?.jobDiscription
                  ? `errorTextInput placeholder:text-red-500 `
                  : "validTextInput"
              }
              value={jobdata.jobDiscription}
              name="jobDiscription"
              onChange={(e) => {
                if (errormsg?.jobDiscription) {
                  delete errormsg["jobDiscription"];
                }
                handleChange({ e, maxlen: 201 });
              }}
              cols="50"></textarea>
            <div className="flex justify-end w-full px-5">
              {countWords(jobdata?.jobDiscription)}/200
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full px-6">
          <h1 className="">Post Job For Single Date ?</h1>{" "}
          <label>
            <Switch
              checked={isdayjob}
              onChange={() => handleSingleDayJob()}
              style={{ backgroundColor: isdayjob ? "red" : "gray" }}
            />
          </label>
        </div>
        <div className="flex justify-between w-full px-6">
          {!isdayjob ? (
            <>
              {" "}
              <div
                className={
                  errormsg?.startdate ? `eFrrorInputField` : "validInputField"
                }
                style={{ padding: "0.5rem 0.5rem 0.5rem 20px" }}
                onClick={() => handleShowPicker("startDate")}>
                <div className="w-[90%]">
                  <div
                    className={
                      errormsg?.startdate ? "text-red-500 text-sm" : "text-sm"
                    }>
                    From <span className="text-error">*</span>
                  </div>
                  <div>
                    <input
                      className={`hide-calendar w-full outline-none text-sm ${
                        errormsg?.startdate && "text-red-500"
                      }`}
                      type="date"
                      ref={startDateRef}
                      min={new Date().toISOString().split("T")[0]}
                      max={jobdata.enddate}
                      value={jobdata.startdate}
                      onChange={(e) => {
                        delete errormsg["startdate"];
                        setJobData({ ...jobdata, startdate: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <img
                  loading="lazy"
                  src="/images/calander-icon.svg"
                  className="cursor-pointer size-5"
                  onClick={() => handleShowPicker("startDate")}
                />
              </div>
              <div
                className={
                  errormsg?.enddate ? `eFrrorInputField` : "validInputField"
                }
                style={{ padding: "0.5rem 0.5rem 0.5rem 20px" }}
                onClick={() => handleShowPicker("endDate")}>
                <div className="w-[90%]">
                  <div
                    className={
                      errormsg?.enddate ? "text-red-500 text-sm" : "text-sm"
                    }>
                    To <span className="text-error">*</span>
                  </div>
                  <div>
                    <input
                      className="w-full text-sm outline-none hide-calendar"
                      type="date"
                      ref={endDateRef}
                      min={
                        jobdata.startdate ||
                        new Date().toISOString().split("T")[0]
                      }
                      value={jobdata.enddate}
                      onChange={(e) =>
                        setJobData({ ...jobdata, enddate: e.target.value })
                      }
                    />
                  </div>
                </div>
                <img
                  loading="lazy"
                  src="/images/calander-icon.svg"
                  className="cursor-pointer size-5"
                  onClick={() => handleShowPicker("endDate")}
                />
              </div>
            </>
          ) : (
            <>
              {" "}
              <div
                className={
                  errormsg?.startdate ? `eFrrorInputField` : "validInputField"
                }
                style={{ padding: "0.5rem 0.5rem 0.5rem 20px" }}>
                <div className="w-[90%]">
                  <div
                    className={
                      errormsg?.startdate ? "text-red-500 text-sm" : "text-sm"
                    }>
                    From<span className="text-error">*</span>
                  </div>
                  <div onClick={() => handleShowPicker("startDate")}>
                    <input
                      className="w-full text-sm outline-none hide-calendar"
                      type="date"
                      ref={startDateRef}
                      min={new Date().toISOString().split("T")[0]}
                      // max={jobdata.enddate}
                      value={jobdata.startdate}
                      onChange={(e) => {
                        setJobData({
                          ...jobdata,
                          startdate: e.target.value,
                          enddate: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <img
                  loading="lazy"
                  src="/images/calander-icon.svg"
                  className="cursor-pointer size-5"
                  onClick={() => handleShowPicker("startDate")}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex justify-between w-full px-6">
          <div
            className="flex border-2 border-[#9d9d9d] rounded-[40px] items-center w-[40%] justify-between p-2 gap-4"
            onClick={() => handleShowPicker("startTime")}>
            <div className="flex items-center justify-center w-full gap-4">
              <img
                className="hover:cursor-pointer"
                loading="lazy"
                src="/images/completeProfile/stop-watch.png"
              />
              <div className="w-full">
                <div className="text-sm">
                  Shift Start Time <span className="text-error">*</span>
                </div>
                <div>
                  <input
                    type="time"
                    ref={startTimeRef}
                    className="w-full text-sm outline-none hide-calendar "
                    value={
                      jobdata.starTime != ""
                        ? jobdata.starTime
                        : dayjs().format("HH:mm")
                    }
                    onChange={(e) =>
                      setJobData({ ...jobdata, starTime: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <img
              src="/images/Chevron.svg"
              className="cursor-pointer"
              alt=""
              onClick={() => handleShowPicker("startTime")}
            />
          </div>
          <div
            className="flex border-2 border-[#9d9d9d] rounded-[40px] items-center justify-between w-[40%] p-2 gap-4"
            onClick={() => handleShowPicker("endTime")}>
            <div className="flex items-center justify-center w-full gap-4">
              <img
                loading="lazy"
                src="/images/completeProfile/stop-watch.png"
              />
              <div className="w-full">
                <div className="text-sm">
                  Shift End Time <span className="text-error">*</span>
                </div>
                <div>
                  <input
                    className="w-full text-sm outline-none hide-calendar"
                    type="time"
                    ref={endTimeRef}
                    value={
                      jobdata.endTime || dayjs().add(1, "hour").format("HH:mm")
                    }
                    onChange={(e) =>
                      setJobData({ ...jobdata, endTime: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <img
              src="/images/Chevron.svg"
              className="cursor-pointer"
              alt=""
              onClick={() => handleShowPicker("endTime")}
            />
          </div>
        </div>
      </div>
      <div
        className="bg-red-600 w-[40%] rounded-[40px] min-h-16 flex mt-8 justify-center items-center text-white text-lg hover:cursor-pointer"
        onClick={() => handleSaveNext()}>
        Save & Next
      </div>
    </div>
  );
}
export default Jp1;
