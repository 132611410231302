import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BarJobCards from "../../../../jobCards/BarJobCards";

function CurrentG({ data }) {
  return (
    <div className="flex flex-wrap w-full gap-6 px-2 pt-6 max-md:max-w-full ">
      {data.map((elem, index) => {
        return <BarJobCards elem={elem} index={index} />;
      })}
      {data?.length < 1 && (
        <div className="flex flex-col items-center justify-center w-full">
          <img src="/images/No_Data.svg" sizes="20" alt="" />
          <p className="text-xl font-medium font-Roboto translate-y-[-1rem]">
            No Current Gigs{" "}
          </p>
        </div>
      )}
    </div>
  );
}

export default CurrentG;
