import * as React from "react";
import { Link, useNavigate } from "react-router-dom";

const DropdownEst = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex absolute z-50 right-[4.5rem] top-[6rem] bg-gray-100 flex-col justify-center mt-5 ml-5 text-sm text-black border border-solid border-neutral-200 rounded-2xl w-fit max-w-[290px] p-5 pr-10"
      ref={ref}>
      <div className="flex flex-col items-start gap-6 ">
        <div className="flex gap-3 cursor-pointer whitespace-nowrap">
          <img
            loading="lazy"
            src="/images/DropDown/Profile.svg"
            className="w-6 shrink-0 aspect-square"
          />
          <div
            className="self-start flex-auto mt-1"
            onClick={() => navigate("/profile", { state: { active: 8 } })}>
            My Profile
          </div>
        </div>
        <div className="flex gap-3 whitespace-nowrap">
          <img
            loading="lazy"
            src="/images/DropDown/Setting.svg"
            className="w-6 shrink-0 aspect-square"
          />
          <Link to={"/settings"} className="my-auto">
            Settings
          </Link>
        </div>
        <div className="flex gap-2.5 cursor-pointer">
          <img
            loading="lazy"
            src="/images/DropDown/AboutUs.svg"
            className="w-6 shrink-0 aspect-square"
          />
          <Link to={"/aboutus"} className="self-start ">
            About Us
          </Link>
        </div>
        <div className="flex gap-2.5">
          <img
            loading="lazy"
            src="/images/DropDown/Logout.svg"
            className="w-6 h-6 shrink-0 aspect-square"
          />
          <Link to={"/logout"} className="self-start ">
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
});
export default DropdownEst;
