import React, { useEffect, useRef, useState } from "react";
import { blockUser, sendChatMessages } from "../../axios/axios";
import { useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import configuration from "../../config/configuration";
import { PiUserCircleThin } from "react-icons/pi";

function MessagesSection({
  setActiveUser,
  activeuser,
  inputtext,
  setInputText,
  setModalShow3,
  setModalShow2,
  fetchChatMsgs,
  msgs,
  setMsgs,
  setChange,
  change,
  isFaq,
}) {
  const [showemoji, setShowEmoji] = useState(false);
  const [showModal1, setModalShow1] = useState(false);
  const user = useSelector((state) => state.user.user);
  const ref = useRef(null);

  const handleEmojiClick = (emojiObject) => {
    setInputText((prevInputText) => prevInputText + emojiObject.emoji);
  };

  const handlesubmitclick = () => {
    sendChatMessages({
      token: user?.token,
      text: `Hello ${activeuser.name}`,
      chatId: activeuser.chatId,
    })
      .then((result) => {
        let res = result.data;
        let x = { ...msgs };
        x = {
          ...x,
          history: [...x.history, res],
        };

        setMsgs(x);
        setChange(!change);
        setInputText("");
      })
      .catch((err) => console.log(err));
  };

  const sendChatMsg = () => {
    if (inputtext.trim() != "") {
      sendChatMessages({
        token: user?.token,
        text: inputtext,
        chatId: activeuser.chatId,
      })
        .then((result) => {
          let res = result.data;
          let x = { ...msgs };
          x = {
            ...x,
            history: [...x.history, res],
          };

          setMsgs(x);
          setChange(!change);
          setInputText("");
        })
        .catch((err) => console.log(err));
    }
  };

  const UnblockUsr = (action) => {
    blockUser({ token: user?.token, action, chatId: activeuser?.chatId })
      .then((res) => {
        toast.success(res.message);
        setActiveUser({ ...activeuser, isBlocked: false });
        setModalShow3(false);
      })
      .catch((err) => toast.error(err.data.message));
  };

  const handleSendMsgChange = (e) => {
    if (e.key === "Enter" && inputtext.trim()) {
      sendChatMsg();
    }
  };

  const handleScroll = () => {
    const node = ref?.current;

    if (node.scrollTop < 50 && msgs.next) {
      fetchChatMsgs({ _id: activeuser.chatId, activePg: msgs.page + 1 });
    }
  };

  const groupMessagesByDate = (messages) => {
    const today = dayjs().startOf("day");
    const yesterday = today.subtract(1, "day");

    return messages.reduce((acc, msg) => {
      const msgDate = dayjs(msg.createdAt).startOf("day");
      let dateLabel;

      if (msgDate.isSame(today)) {
        dateLabel = "Today";
      } else if (msgDate.isSame(yesterday)) {
        dateLabel = "Yesterday";
      } else {
        dateLabel = msgDate.format("D MMMM YYYY");
      }

      if (!acc[dateLabel]) acc[dateLabel] = [];
      acc[dateLabel].push(msg);
      return acc;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(msgs.history);

  useEffect(() => {
    const msgDiv = ref.current;
    if (msgDiv) {
      msgDiv.scrollTop = msgDiv?.scrollHeight;
    }
  }, [change]);

  return (
    <div className="relative flex flex-col h-full px-5">
      <div className="flex justify-between gap-5 pb-3 border-b-2 max-md:flex-wrap">
        <div className="flex gap-4 ">
          {isFaq ? (
            <img
              loading="lazy"
              src={"/images/barooom.png"}
              className="shrink-0 rounded-full aspect-square w-[55px]"
            />
          ) : activeuser?.pic != "" ? (
            <img
              loading="lazy"
              src={`${configuration.cdnFront}/${activeuser?.pic}` || ""}
              className="shrink-0 rounded-full aspect-square w-[55px]"
            />
          ) : (
            <PiUserCircleThin style={{ width: "52px", height: "52px" }} />
          )}
          <div className="flex flex-col">
            <div className="text-base text-black">
              {isFaq ? activeuser?.subject : activeuser?.name}
            </div>
            {/* <div className="self-start  text-xs text-emerald-400 max-md:ml-2.5">
                <span className="mr-[4px] text-sm">&#x2022;</span>
                Online
              </div> */}
          </div>
        </div>
        {!activeuser.isBlocked && !isFaq && (
          <button
            onClick={() => setModalShow1((setModalShow1) => !setModalShow1)}>
            &#x2022;&#x2022;&#x2022;
          </button>
        )}
        {showModal1 && (
          <div className="absolute right-5 translate-y-10 h-fit border-2 rounded-[25px]  bg-white p-5">
            <div className="flex flex-col justify-center text-xl max-w-[190px]">
              <button
                onClick={() => setModalShow3(true) || setModalShow1(false)}
                className="text-red-500">
                Block
              </button>
              <div className="h-px mt-1 border border-solid shrink-0 bg-zinc-300 border-zinc-300" />
              <button
                onClick={() => setModalShow2(true) || setModalShow1(false)}
                className="mt-1 text-neutral-700">
                Report
              </button>
            </div>
          </div>
        )}
      </div>
      {msgs.history.length >= 1 && (
        <div
          className="overflow-y-scroll no-scrollbar h-[80%]"
          ref={ref}
          onScroll={handleScroll}>
          <div className="">
            {Object.keys(groupedMessages).map((dateLabel, ind) => (
              <div
                key={`${dateLabel}_Rand_${ind}`}
                className="flex flex-col gap-2">
                <div className="my-2 text-xs font-medium text-center">
                  {dateLabel}
                </div>
                {groupedMessages[dateLabel].map((msg, index) => (
                  <div
                    key={`GPM_${msg._id}_${index}`}
                    className={`flex ${
                      msg.from === user._id ? "justify-end" : "justify-start"
                    }`}>
                    <div
                      className={`${msg.from === user._id ? "text-end" : ""}`}>
                      <div
                        style={{
                          borderRadius: `${
                            msg.from === user._id
                              ? "15px 15px 0 15px"
                              : "15px 15px 15px 0"
                          }`,
                        }}
                        className={`${
                          msg.from === user._id
                            ? user?.role == 1
                              ? "bg-[#FFCC42] text-white"
                              : "bg-estab-back text-white"
                            : "bg-neutral-100"
                        } p-2 max-w-xs`}>
                        {msg.text}
                      </div>
                      <p className="text-xs">
                        {dayjs(msg.createdAt).format("hh:mm A")}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
      {msgs.history.length < 1 && (
        <div className="flex items-center justify-center w-full h-[80%]">
          <img
            src="/images/No_Msg.svg"
            alt="Image"
            className="h-[50px] w-auto cursor-pointer"
            onClick={handlesubmitclick}
          />
        </div>
      )}
      {!activeuser.isBlocked ? (
        <div className="flex w-full py-4 mt-1 text-sm leading-6 px-7 rounded-3xl bg-neutral-100 text-stone-500 text-opacity-109 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex w-full gap-2">
            <img
              loading="lazy"
              src="/images/chatEmoji.png"
              className="shrink-0 aspect-square w-[23px]"
              onClick={() => setShowEmoji(!showemoji)}
            />
            <input
              onClick={() => setShowEmoji(false)}
              className="w-full text-[18px] bg-neutral-100 outline-none border-none"
              type="text"
              value={inputtext}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={(e) => handleSendMsgChange(e)}
              placeholder={
                !isFaq
                  ? `Yeah, Me too! Let’s go out tonight.`
                  : "Type your message..."
              }
            />
          </div>
          <img
            loading="lazy"
            src="/images/chatEnter.png"
            className="shrink-0 self-start aspect-[1.1] fill-black w-[23px]"
            onClick={() => {
              sendChatMsg();
              setInputText("");
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-32 max-h-32 rounded-t-3xl">
          {activeuser.blockedBy === user._id ? (
            <>
              <p>You have blocked {activeuser?.name}</p>
              <p className="text-sm text-stone-500">
                Unblock {activeuser?.name || "User"} to start sending Messages
                again !
              </p>
              <button
                onClick={() => UnblockUsr(false)}
                className="p-2 text-white rounded-full cursor-pointer bg-estab-back">
                Unblock Chat
              </button>
            </>
          ) : !isFaq ? (
            <>
              <p>You are blocked by {activeuser?.name}</p>
              <p className="text-sm text-stone-500">
                {activeuser?.name || "User"} need's to Unblock you to sending
                Messages again !
              </p>
            </>
          ) : (
            <>
              <p>Ticket Closed</p>
              <p className="text-sm text-stone-500">
                If your query is not resolve feel free to open new Ticket !
              </p>
            </>
          )}
        </div>
      )}
      {showemoji && (
        <div className="absolute bottom-0 translate-y-[-10%] translate-x-[25px]">
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </div>
      )}
    </div>
  );
}

export default MessagesSection;
