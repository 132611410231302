import React, { useEffect } from "react";
import config from "../../../../../config/configuration";
import { Button } from "antd";
import { ApplyForGig } from "../../../../../axios/axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BarJobCards from "../../../../jobCards/BarJobCards";

function NewOpportunities({ data, handleApplyGig }) {
  return (
    <div className="flex flex-wrap w-full gap-6 px-2 pt-6 max-md:max-w-full">
      {data.map((elem, index) => {
        return (
          <BarJobCards
            elem={elem}
            Jtype={2}
            index={index}
            handleApplyGig={handleApplyGig}
          />
        );
      })}
      {data?.length < 1 && (
        <div className="flex flex-col items-center justify-center w-full">
          <img src="/images/No_Data.svg" sizes="20" alt="" />
          <p className="text-xl font-medium font-Roboto translate-y-[-1rem]">
            No New Opportunities{" "}
          </p>
        </div>
      )}
    </div>
  );
}

export default NewOpportunities;
